import React from "react"

import StyledDetails from "./style/privacyPolocy"

const PrivacyPolicy = () => {
  return (
    <StyledDetails>
      <div className="main-container">
        <h2>Privacy Policy for Cresol Infoserv</h2>
        <p>
          At Cresol Infoserv, we are committed to protecting the privacy and
          confidentiality of our clients, partners, and website visitors. This
          Privacy Policy outlines how we collect, use, store, and protect
          personal information. By engaging with our services or accessing our
          website, you consent to the practices described in this policy.
        </p>
        <div className="container">
          <h3>1. Information We Collect:</h3>
          <p>
            Personal Information: When you engage with our services or interact
            with our website, we may collect personal information such as your
            name, email address, phone number, and company details.
          </p>
          <p>
            Website Usage Information: We may collect non-personal information
            such as your IP address, browser type, device information, and
            website usage patterns through the use of cookies and similar
            technologies.
          </p>
        </div>

        <div className="container">
          <h3>2. Use of Personal Information:</h3>
          <p>
            We may use your personal information to provide and improve our
            services, respond to your inquiries, and communicate with you about
            our offerings.
          </p>
          <p>
            Personal information may be used for marketing purposes, such as
            sending promotional materials, newsletters, or information about
            events and webinars. You can opt out of such communications at any
            time.
          </p>
          <p>
            We do not sell or rent your personal information to third parties.
            However, we may share it with trusted service providers who assist
            us in delivering our services.
          </p>
        </div>

        <div className="container">
          <h3>3. Data Retention:</h3>
          <p>
            We retain personal information for as long as necessary to fulfill
            the purposes outlined in this Privacy Policy, unless a longer
            retention period is required or permitted by law.
          </p>
          <p>
            If you no longer wish to have your personal information held by us,
            you can request its deletion. However, please note that certain data
            may be retained for legal or legitimate business purposes.
          </p>
        </div>

        <div className="container">
          <h3>4. Data Security:</h3>
          <p>
            We implement appropriate security measures to protect personal
            information from unauthorized access, alteration, disclosure, or
            destruction.
          </p>
          <p>
            While we strive to protect your personal information, no method of
            transmission over the internet or electronic storage is completely
            secure. Therefore, we cannot guarantee absolute security.
          </p>
        </div>

        <div className="container">
          <h3>5. Third-Party Links:</h3>
          <p>
            Our website may contain links to third-party websites or services
            that are not operated or controlled by Cresol Infoserv. This Privacy
            Policy does not apply to those third-party websites, and we
            encourage you to review their privacy policies.
          </p>
        </div>

        <div className="container">
          <h3>6. Compliance with Laws:</h3>
          <p>
            We comply with applicable data protection laws and regulations,
            including the General Data Protection Regulation (GDPR) and any
            other applicable local privacy laws.
          </p>
        </div>

        <div className="container">
          <h3>7. Updates to the Privacy Policy:</h3>
          <p>
            We may update this Privacy Policy from time to time. Any changes
            will be reflected on this page, and the revised policy will become
            effective when posted.
          </p>
        </div>

        <div className="container">
          <h3>8. Contact Us:</h3>
          <p>
            If you have any questions, concerns, or requests regarding this
            Privacy Policy or the handling of your personal information, please
            contact us at [admin@cresolinfoserv.in].
          </p>
        </div>

        <p>
          By using our services or accessing our website, you acknowledge that
          you have read and understood this Privacy Policy and consent to the
          collection, use, and disclosure of your personal information as
          described herein.
        </p>
      </div>
    </StyledDetails>
  )
}

export default PrivacyPolicy
