import tw, { styled } from "twin.macro"

const StyledDetails = styled.section`
  ${tw`md:py-8 py-6`};
  .main-container {
    ${tw`flex flex-col md:(px-5) px-3 max-w-8xl mx-auto z-[1] relative gap-x-16 space-y-8 gap-4`}
    h1 {
      ${tw`text-3xl font-bold`}
    }
    h3 {
      ${tw`text-xl font-medium`}
    }
    .container {
      ${tw`space-y-4`}
    }
  }
`

export default StyledDetails
