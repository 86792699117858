import React from "react"
import Layout from "../components/layout"
import PrivacyPolicy from "../components/privacy-policy/PrivacyPolicy"
import MetaTags from "react-meta-tags"
import SEO from "../components/seo"

const privacyPolicy = () => {
  return (
    <div>
      <Layout>
        <SEO
          title="Privacy Policy | Cresol Infoserv"
          description="Learn how Cresol Infoserv collects, uses, and protects your personal information. Your privacy and security are our top priorities."
          keywords="best website development company, website design and development services, best digital marketing services, software testing services, mobile app development company"
        />
        <PrivacyPolicy />
      </Layout>
    </div>
  )
}

export default privacyPolicy
